import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Space } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import { addToCart, fetchCartProducts, reduceFromCart } from "store/slices/cartSlice";
import { setSigninModalIsOpen } from "store/slices/layoutSlice";
import useCustomer from "hooks/useCustomer";

const CartQtyButtonGroup = ({
  item,
  size = "middle",
  block = false,
}) => {
  const dispatch = useDispatch();
  const { customer } = useCustomer();
  const { data } = useSelector((state) => state.cart.cartProducts);
  const inCart = data.products?.find((p) => +p.product_id === +item.product_id);

  const handleAddToCart = async (product_id, quantity = 1) => {
    // для яндекс браузера
    if (
      // (window.navigator.userAgent.includes("YaBrowser") && window.navigator.userAgent.includes("Mobile")) ||
      // (window.navigator.userAgent.includes("Tablet") ||
      // window.navigator.userAgent.includes("iPhone") ||
      // window.navigator.userAgent.includes("iPad"))
      window.navigator.userAgent.toLowerCase().includes("tablet") ||
      window.navigator.userAgent.toLowerCase().includes("mobile") ||
      window.navigator.userAgent.toLowerCase().includes("iphone") ||
      window.navigator.userAgent.toLowerCase().includes("ipad")
    ) {
      if (!customer.token) {
        dispatch(setSigninModalIsOpen(true));
        return;
      }
    }

    await addToCart(product_id, quantity);
    dispatch(fetchCartProducts());
  };

  const handleReduceToCart = async () => {
    await reduceFromCart(item.product_id, 1);
    dispatch(fetchCartProducts());
  };

  return (
    <Space.Compact size={size} block={block}>
      {inCart ? (
        <>
          <Button
            onClick={handleReduceToCart}
            type="default"
            icon={<MinusOutlined style={{ fontSize: 22 }} />}
          />
          <Input
            // value={formatWeightWithUnit(inCart.weight, inCart.quantity)}
            value={`${inCart.quantity} шт`}
            className={`quantity-input quantity-input__${size}`}
          />
          <Button
            onClick={() => handleAddToCart(item.product_id, item.minimum)}
            type="primary"
            icon={<PlusOutlined style={{ fontSize: 22 }} />}
          />
        </>
      ) : (
        <Button
          block={block}
          type="primary"
          onClick={() => handleAddToCart(item.product_id, item.minimum)}
        >
          <small>В КОРЗИНУ</small>
        </Button>
      )}
      
    </Space.Compact>
  );
};

export default CartQtyButtonGroup;
